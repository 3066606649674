// network
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoader from "../webx/webb-loader";
import NavsButtonBack from "../webx/navs-button-back";
import NavsButtonNext from "../webx/navs-button-next";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { TransfersList } from "../../services/srvc-transfers-realm";

// lists --------------->

const listStatus = [
  {code: 'claim', color:'back-color-warning', back:'#FFF8E1', main:'#FFAB00', name:'Claim'},
  {code: 'burn', color:'back-color-danger', back:'#FFCCBC', main:'#DD2C00', name:'Burn'},
  {code: 'mint', color:'back-color-success', back:'#F9FBE7', main:'#00C853', name:'Mint'},
  {code: 'renew', color:'back-color-success', back:'#F9FBE7', main:'#00C853', name:'Renew'},
  {code: 'transfer', color:'back-color-success', back:'#F9FBE7', main:'#00C853', name:'Transfer'},
  {code: 'data', color:'bg-info', back:'#E0F7FA', main:'#0091EA', name:'Data'},
  {code: 'checkin', color:'bg-info', back:'#E0F7FA', main:'#0091EA', name:'Check-In'}
]

// code --------------->

export default function TransfersListModule() {

  const usxx = GetUserForm();
  const asset = GetUserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  
  const [loading, setLoading] = useState(true);
  
  const [list, setList] = useState([])
  const [data, setData] = useState([])

  const [search, setSearch] = useState()

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(10)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState()
  const [total, setTotal] = useState()

  const [text, setText] = useState('')


  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        
        setLoading(true);
        
        const result = (await TransfersList(({
          data: {user: asset.item, index: index, items: items, filters:{name: search} },
          user: asset.item
        })))
        // console.log (result.data)
        setList(result.data.list)
        setData(result.data.list)

        // set index
        setTotal(result.data.count)
        setText(`${((index-1) * items )} - ${index * items < result.data.count ? index * items : result.data.count} of ${result.data.count}`)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[search, index, items]);


  const NextIndex = async() =>{
    if(total == index) {}
    else {
      setNextIndex(curr+1)
      setIndex(curr+1)
      setCurrentIndex(curr+1)
    }
  }

  const LastIndex = async()=>{
    if(index == 1) {}
    else{
      setLastIndex(curr-1)
      setIndex(curr-1)
      setCurrentIndex(curr-1)
    }
  }


  // if (loading) return <WebbLoader/>
  // if (!data || data.length===0) return <>Your NFT Transfers</>

  return (
    <>
    {/* search-box */}
    <div className={!loading && data.length !== 0 ? 'rounded-wd': 'd-none'}>
      
      <div className="form-group mb-3">  
        <input type="text" className="form-control height-md border-none "              
          value={search}
          onChange={({ target }) => {setSearch(target.value); }}
          // disabled={data.length==0}
          placeholder="Search">
        </input>
      </div>

    </div>

    {/* loader */}
    <div className={loading ? '' : 'd-none'}>
      {/* <WebbLoader/> */}
    </div>

    {/* no search data */}
    <div className={!loading && search !== "" && data.length === 0 ? 'text-center': 'd-none'}>
      {/* <span><i className="bx bxs-circle text-color-danger"></i></span>
      <span className="ms-1"></span> */}
      <span className="text-color-tone">Tokenize Events Transactions</span>
    </div>  

    {/* data */}
    <div className={!loading && (data) ? 'rounded-wd border border-light back-color-wite': 'd-none'}>
      {data && data.length > 0 && data.map((item, i) => ( 

        <div className={i < data.length-1 ? 'border-bottom border-light' : ''} key={i}>
          
          <div className="d-flex cursor p-2 px-3 hitone">

            <div className="mt-1">
              <Jazzicon diameter={33} seed={jsNumberForAddress(item.item)} />
            </div>

            <div className="ms-2">
              <p className="m-0" >
                {item.user.name || 'Alice Wonderland'}
              </p>
              <p className="text-small text-color-tone m-0">{item.wize}</p>
            </div>
            
            <div className="ms-auto py-2 mt-1 text-small text-end">
              <span className={`rounded-md p-1 px-2 small`} 
                style={{
                  color: listStatus.find( x => x.code === item.task).main,
                  backgroundColor: listStatus.find( x => x.code === item.task).back,
                  borderColor: listStatus.find( x => x.code === item.task).main,
                  borderStyle:'solid', borderWidth:'0.01rem',
                }}>
                {listStatus.find( x => x.code === item.task).name}
              </span>
            </div>

          </div>

        </div>

      ))}
    </div>


    {/* navs */}
    <WebbDividerSmall />
    <div className={data.length < items ? 'd-none' : ''}>
      <div className="d-flex justify-content-between">

        <div className="" onClick={()=>LastIndex()}>
          <NavsButtonBack />
        </div>

        <div className="">
          <p className="my-2 text-color-tone">{text}</p>
        </div>

        <div className="" onClick={()=>NextIndex()}>
          <NavsButtonNext />
        </div>

      </div>
    </div>
  
  </>

  )
}