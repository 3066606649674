// main
import {Helmet} from "react-helmet";

import ContentFormat from "../content/webz/content-format-xw";
import WebbHeader from "../content/webz/webb-header-xw";
// import WebbFooter from "../content/webz/webb-footer-xw";

import WebbDividerSmall from "../content/webx/webb-divider-sm";
import WebbDividerMedium from "../content/webx/webb-divider-md";

import NetworkListModule from "../content/network/network-list";

export default function Network () {
  
  const meta = {
    name: 'Events',
    memo: '',
    head: ''
  }

  return(

  <>
    <Helmet>
      <title>{meta.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
      <meta name="description" content={process.env.REACT_APP_WEBB_SITE_MEMO}/>
      <link rel="canonical" href="https://tokenize.ee" />
    </Helmet>
    
    <ContentFormat 
      
      name = {meta.name}
      header = {{ size: 'medium', show: true, data: 
      <>
        <div className="">
          <WebbHeader data = {{name: meta.name, home: '/', link: '/'}}/>
        </div>
      </>
      }}

      media = {{ size: 'xtra', data: 
      <></>
      }}

      content = {{ size: 'medium', data: 
      <>

        <WebbDividerSmall />
        <NetworkListModule />


        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
      </>
      }}
    
      footer = {{ size: 'medium', data: 
      <></>
      }}
    
    
    ></ContentFormat>


  </>
  )
}