// network
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoader from "../webx/webb-loader";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { TeamInvite } from "../../services/srvc-network-realm";


// lists --------------->
const listRoles = ['owner', 'admin', 'member']

// code --------------->

export default function TeamInviteModule() {

  const usxx = GetUserForm();
  const asset = GetUserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const navigate = useNavigate()

  const [form, setForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [data, setData] = useState({
    name: '',
    mail: '',
    mobile: '',
    role: 'admin'
  })

  // form validate
  useEffect( () => {
    if (data.name!=='' && data.mail!=='' && data.role!==''){
      setForm(true)
    } else {}
  },[data]);


  const handleSubmit = async() => {
    
    setSubmit(true)
    setLoading(true)

    const datx = {
      user: { name: data.name, mail: data.mail, mobile: data.mobile },
      role: data.role,
      team: asset.item
    }

    const result = await TeamInvite({data: datx, user: asset.item})
    console.log(result)

    if (result.data) setDone(true)
    setLoading(false)

  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }


  if (loading) return <WebbLoader/>

  return (
    <>
    {/* intro */}
    <p>Enter Details and Invite Team Members</p>

    {/* data */}
    <WebbDividerSmall/>
    <div className={!loading && !submit && !done ? '' : 'd-none'}>

      <div className="form-group mb-3">  
        <label className="form-label small">Name <FormNeeded/></label>
        <input type="text" 
          className="form-control height-md border-none"
          style={{fontSize:'0.9rem', height:'2.7rem'}} 
          value={data.name}
          onChange={({ target }) => {handleChange("name", target.value); }}
          disabled={loading}
          placeholder="Enter Name">
        </input>
      </div>  

      <div className="form-group mb-3">  
        <label className="form-label small">Email <FormNeeded/></label>
        <input type="text" 
          className="form-control height-md border-none"
          style={{fontSize:'0.9rem', height:'2.7rem'}} 
          value={data.mail}
          onChange={({ target }) => {handleChange("mail", target.value); }}
          disabled={loading}
          placeholder="alex@tokenize.so">
        </input>
      </div>  

      <div className="form-group mb-3">  
        <label className="form-label small">Mobile</label>
        <input type="text" 
          className="form-control height-md border-none"
          style={{fontSize:'0.9rem', height:'2.7rem'}} 
          value={data.mobile}
          onChange={({ target }) => {handleChange("mobile", target.value); }}
          disabled={loading}
          placeholder="9876540123">
        </input>
      </div>        

      <div className="mb-3">
        <label className="form-label mb-3">Mode <FormNeeded/></label>
        <div className="mb-3">
          {listRoles && listRoles.map((item, i) => (
            <span className={`p-2 px-3 rounded-md me-2 cursor hitone ${item === data.role ? 'back-color-rich' : 'back-color-wite'}`} 
              key={i}
              onClick={() => handleChange('role',item)}
            >
              {item}
            </span>
          ))}
        </div>
      </div>



    </div>

    {/* action */}
    <div className={!loading && !submit && !done ? '' : 'd-none'}>
      <WebbDividerMedium />
      <div className="d-flex">
        <div className="me-auto">
          <button className="btn btn-light rounded-wd back-color-lite border text-small"
            onClick={() => navigate(-1)}
          >Cancel</button>
        </div>

        <div className="">
          <button className="btn btn-info rounded-wd border-none back-color-next text-color-wite text-small"
            disabled={!form || loading}
            onClick={() => handleSubmit()}
          >Submit</button>
        </div>
      </div>

    </div>


    <div className={!loading && submit ? '' : 'd-none'}>

      <div className={done ? '' : 'd-none'}>
        <div className="rounded-wd back-color-wite p-3 text-center">
          Successful! User added to your team
        </div>
      </div>

      <div className={!done ? '' : 'd-none'}>
        <div className="rounded-wd back-color-wite p-3 text-center">
          Error! Please try again.
        </div>
      </div>

    </div>
    

  </>

)
}