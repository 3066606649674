// main
import { useParams } from "react-router-dom";
import {Helmet} from "react-helmet";

import ContentFormat from "../content/webz/content-format-xw";
import WebbHeader from "../content/webz/webb-header-xw";

export default function HomeUser () {
  
  const meta = {
    name: 'Home',
    memo: '',
    head: ''
  }

  return(

  <>
    <Helmet>
      <title>{meta.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
      <meta name="description" content={process.env.REACT_APP_WEBB_SITE_MEMO}/>
      <link rel="canonical" href="http://wize.ws" />
    </Helmet>
    
    <ContentFormat 
      
      name = {meta.name}
      header = {{ size: 'medium', data: 
      <>
        <div className="">
          <WebbHeader data = {{name: meta.name, home: '/', link: '/'}}/>
        </div>
      </>
      }}

      media = {{ size: 'xtra', data: 
      <></>
      }}

      content = {{ size: 'medium', data: 
      <>
        
      </>
      }}
    
      footer = {{ size: 'medium', data: 
      <></>
      }}
    
    
    ></ContentFormat>


  </>
  )
}