// main
import {Helmet} from "react-helmet";

import ContentFormat from "../content/webz/content-format-xz";
import WebbHeader from "../content/webz/webb-header-xx";
import WebbIconFull from "../content/webx/webb-icon-full";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";

import AuthMailFirebaseModule from "../content/auth/auth-mail-fbaselink";


export default function AuthMailFirebase () {
  
  const meta = {
    name: 'Account Access',
    media: 'https://img.freepik.com/premium-photo/highway-metaverse-city-3d-render_84831-145.jpg?w=996'
  }

  return(

  <>
    <Helmet>
      <title>{meta.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}</title>
      <link rel="canonical" href="http://mysite.com/example" />
    </Helmet>
      
    <ContentFormat 
      
      name = {meta.name}

      header = {{ size: 'medium', data: 
      <>
        <div className="">
          <WebbHeader data = {{name: meta.name, home: '/', link: '/'}}/>
        </div>
      </>
      }}

      media = {{ size: 'xtra', data: 
      <>
        <div className="">
         
        </div>
      </>
      }}

      content = {{ size: 'mini', data: 
      <>
        <WebbDividerSmall />
        <WebbIconFull />

        <WebbDividerSmall />
        <AuthMailFirebaseModule />

      </>
      }}
    
      footer = {{ size: 'medium', data: 
      <>
        <div className="">
        
        </div>
      </>
      }}
    
    ></ContentFormat>


  </>
  )
}