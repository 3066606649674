// next


export default function NextDivider() {

  return (
  <>
    <div className="border-bottom mb-3"></div>
  </>
  )
}