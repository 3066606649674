// network
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoader from "../webx/webb-loader";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";


// lists --------------->


// code --------------->

export default function NetworkInviteModule() {

  const usxx = GetUserForm();
  const asset = GetUserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  
  const [form, setForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [data, setData] = useState({
    name: '',
    mail: '',
    mobile: '',
  })

  // form validate
  useEffect( () => {
    if (data.name!=='' && data.mail!==''){
      setForm(true)
    } else {}
  },[data]);


  const handleSubmit = async() => {
    
    setSubmit(true)
    setLoading(true)


  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }


  if (loading) return <WebbLoader/>

  return (
    <>
    {/* search-box */}
    <p>Enter Details and Invite User</p>

    {/* data */}
    <WebbDividerSmall/>
    <div className="">

      <div className="form-group mb-3">  
        <label className="form-label small">Name <FormNeeded/></label>
        <input type="text" 
          className="form-control height-md border-none"
          style={{fontSize:'0.9rem', height:'2.7rem'}} 
          value={data.name}
          onChange={({ target }) => {handleChange("name", target.value); }}
          placeholder="Enter Name">
        </input>
      </div>  

      <div className="form-group mb-3">  
        <label className="form-label small">Email <FormNeeded/></label>
        <input type="text" 
          className="form-control height-md border-none"
          style={{fontSize:'0.9rem', height:'2.7rem'}} 
          value={data.mail}
          onChange={({ target }) => {handleChange("mail", target.value); }}
          placeholder="alex@tokenize.so">
        </input>
      </div>  

      <div className="form-group mb-3">  
        <label className="form-label small">Mobile</label>
        <input type="text" 
          className="form-control height-md border-none"
          style={{fontSize:'0.9rem', height:'2.7rem'}} 
          value={data.mobile}
          onChange={({ target }) => {handleChange("mobile", target.value); }}
          placeholder="9876540123">
        </input>
      </div>        


    </div>

    {/* action */}
    <div className="">
      <WebbDividerMedium />
      <div className="d-flex">
        <div className="me-auto">
          <button className="btn btn-light rounded-wd back-color-wite text-small"
            onClick={() => ''}
          >Cancel</button>
        </div>

        <div className="">
          <button className="btn btn-info rounded-wd border-none back-color-next text-color-wite text-small"
            disabled={!form}
            onClick={() => ''}
          >Submit</button>
        </div>
      </div>

    </div>

  </>

)
}