// auth - firebase mail link
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { auth } from '../../services/firebase'
import { signInWithEmailLink } from "firebase/auth";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoader from "../webx/webb-loader";
import WebbSpinner from "../webx/webb-spinner";

import { SetAuthUser } from "../../services/srvc-auth-user";

export default function AuthMailCheckModule () {

  const [username, setUsername] = useState(window.localStorage.getItem('authmail'));

  const [loading, setLoading] = useState(true);  
  const [done, setDone] = useState(true);
  
  const navigate = useNavigate();


  useEffect(() => { 

    if (username) {
      signInWithEmailLink(auth, username, window.location.href)
      .then((result) => {
        setDone(true)
        window.localStorage.removeItem('authmail');
        // console.log(result.user.email);
        SetAuthUser({user: result.user.email})
        navigate('/auth/next')
      })
      .catch((error) => {
        console.log(error);
        setDone(false)
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      });
      // alert('done')
      setLoading(false)

    }
    else {
      // alert('X')
      setLoading(false)
    }

  },[])


  if (loading && username) return <WebbSpinner/>


  return (
  <>

    <div className={!loading && username ? 'back-color-wite mb-3 rounded-wd' : 'd-none'}>
      
      <div className={done ? '' : 'd-none'}>
        <h1 className="lead text-color-main">Success !</h1>
        <p className="">Please Wait...</p>
      </div>
      
      <div className={done ? 'd-none' : ''}>
        <div className="p-3">
          <h1 className="lead text-color-main">Error !</h1>
          <p className="">Please Try Again...</p>
        </div>
      </div>

    </div>

  </>

  )
}