// network
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoader from "../webx/webb-loader";
import NavsButtonBack from "../webx/navs-button-back";
import NavsButtonNext from "../webx/navs-button-next";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { UserTeamList } from "../../services/srvc-teams-realm";


// lists --------------->
const listRoles = [
  {name: 'Owner', code: 'owner', colorback:'#FFCCBC', colortext:'#DD2C00', },
  {name: 'Admin', code: 'admin', colorback:'#F9FBE7', colortext:'#00C853',},
  {name: 'Member', code: 'member', colorback:'#E0F7FA', colortext:'#0091EA',}
]


// code --------------->

export default function TeamListModule() {

  const usxx = GetUserForm();
  const asset = GetUserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [list, setList] = useState([])
  const [data, setData] = useState([])

  const [search, setSearch] = useState()

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(20)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState()
  const [total, setTotal] = useState()

  const [text, setText] = useState('')


  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        
        setLoading(true);
        
        const result = (await UserTeamList(({
          data: {user: asset.item, item: items, index: index, filters:{}},
          user: asset.user
        })))
        // console.log (result.data)
        setData(result.data.list.sort((a,c) => c.name - a.name))
        setList(result.data.list.sort((a,c) => c.name - a.name))

        if (result.data) {
          setTotal(Math.ceil(result.data.count/ items))
          setText(`${(index-1)*items+1} - ${index*items < result.data.count ? index*items : result.data.count} of ${result.data.count}`)
        }

        setLoading(false);
      }
      fetchData()
    } else {}
  },[search, index, items]);

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        
        var searchData = []

        if (search && search != "") {
          console.log (search.toLowerCase())
          
          var result =  (list.filter(x=> 
            x.name.toLowerCase().includes(search.toLowerCase()) 
            || x.mail.toLowerCase().includes(search.toLowerCase()) 
          ));
          searchData = (result)
        }
        else {
          console.log(list)
          searchData = list
        }
        
        setData(searchData)
        
      }
      fetchData()
    } else {}
  },[search]);


  const NextIndex = async() =>{
    if(total == index) {}
    else {
      setNextIndex(curr+1)
      setIndex(curr+1)
      setCurrentIndex(curr+1)
    }
  }

  const LastIndex = async()=>{
    if(index == 1) {}
    else{
      setLastIndex(curr-1)
      setIndex(curr-1)
      setCurrentIndex(curr-1)
    }
  }


  // if (loading) return <WebbLoader/>
  // if (!data || data.length===0) return <>Your Network is Your Networth</>

  return (
    <>
    {/* summary */}
    <div className={''}>
      <div className="row row-cols-4 g-1">
        <div className="col">
        <div className="text-center back-color-wite rounded-wd p-3" >
            <p className="text-lead text-bold m-0" style={{fontSize:'1.5rem'}}>
              {data && data.filter(x=>x.role==='owner').length}
            </p>
            <span>Owners</span>
          </div>
        </div>
        <div className="col">
          <div className="text-center back-color-wite rounded-wd p-3">
            <p className="text-lead text-bold m-0" style={{fontSize:'1.5rem'}}>
              {data && data.filter(x=>x.role==='admin').length}
            </p>
            <span>Admins</span>
          </div>
        </div>
        <div className="col">
          <div className="text-center back-color-wite rounded-wd p-3">
            <p className="text-lead text-bold m-0" style={{fontSize:'1.5rem'}}>
              {data && data.filter(x=>x.role==='member').length}
            </p>
            <span>Members</span>
          </div>
        </div>
        <div className="col">
          <div className="text-center back-color-wite  text-color-next rounded-wd p-3 cursor hitone" 
            onClick={() => navigate(`/${asset.form.substring(0,2)}/teams/invite`)}>
            <p className="text-lead text-bold m-0" style={{fontSize:'1.5rem'}}>
              <i className="bi bi-plus-circle"></i>
            </p>
            <span className="text-color-dark text-sm">Add Member</span>
          </div>
        </div>

      </div>
    </div>

    {/* search-box */}
    <WebbDividerSmall/>
    <div className={'rounded-wd'}>
      
      <div className="form-group mb-3">  
        <input type="text" className="form-control height-md border-none "              
          value={search}
          onChange={({ target }) => {setSearch(target.value); }}
          disabled={data.length==0}
          placeholder="Search">
        </input>
      </div>

    </div>

    {/* loader */}
    <div className={loading ? '' : 'd-none'}>
      {/* <WebbLoader/> */}
    </div>

    {/* no search data */}
    <div className={!loading && search !== "" && data.length === 0 ? '': 'd-none'}>
      <span><i className="bx bxs-circle text-color-danger"></i></span>
      <span className="ms-1"></span>
      <span>No Results. Please refine your Search</span>
    </div>

    {/* data */}
    <div className={!loading && (data && data.length > 0) ? 'rounded-wd border border-light back-color-wite': ''}>
      {data && data.length > 0 && data.map((item, i) => ( 

        <div className={i < data.length-1 ? 'border-bottom border-light' : ''} key={i}>
          
          <div className="d-flex cursor p-2 px-3 hitone">

            <div className="mt-1">
              <Jazzicon diameter={33} seed={jsNumberForAddress(item.user)} />
            </div>

            <div className="ms-2">
              <p className="text-bold m-0">
                {item.name || 'Alice Wonderland'}
              </p>
              <p className="text-small m-0">{item.mail}</p>
            </div>

            <div className="ms-auto py-2 mt-1 text-small text-end">
              <span className={`rounded-md p-1 px-2 small`} 
                style={{
                  color: listRoles.find( x => x.code === item.role).colortext,
                  backgroundColor: listRoles.find( x => x.code === item.role).colorback,
                  borderColor: listRoles.find( x => x.code === item.role).colortext,
                  borderStyle:'solid', borderWidth:'0.01rem',
                }}>
                {listRoles.find( x => x.code === item.role).name}
              </span>
            </div>

          </div>

        </div>

      ))}
    </div>


    {/* navs */}
    <WebbDividerSmall />
    <div className={data.length < items ? '' : ''}>
      <div className="d-flex justify-content-between">

        <div className="" onClick={()=>LastIndex()}>
          <NavsButtonBack />
        </div>

        <div className="">
          <p className="my-2 text-color-tone">{text}</p>
        </div>

        <div className="" onClick={()=>NextIndex()}>
          <NavsButtonNext />
        </div>

      </div>
    </div>


  </>

)
}