// main
import {Helmet} from "react-helmet";

import ContentFormat from "../content/webz/content-format-xw";
import WebbHeader from "../content/webz/webb-header-xx";

import WebbDividerSmall from "../content/webx/webb-divider-sm";
import WebbDividerMedium from "../content/webx/webb-divider-md";



export default function DataList () {
  
  const meta = {
    name: 'Data',
    memo: '',
    head: ''
  }

  return(

  <>
    <Helmet>
      <title>{meta.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
      <meta name="description" content={process.env.REACT_APP_WEBB_SITE_MEMO}/>
      <link rel="canonical" href="https://tokenize.ee" />
    </Helmet>
    
    <ContentFormat 
      
      name = {meta.name}
      header = {{ size: 'medium', show: true, data: 
      <>
        <div className="">
          <WebbHeader data = {{name: meta.name, home: '/', link: '/bz/home'}}/>
        </div>
      </>
      }}

      media = {{ size: 'xtra', data: 
      <></>
      }}

      content = {{ size: 'medium', data: 
      <>
        <WebbDividerSmall />
        

        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
      </>
      }}
    
      footer = {{ size: 'medium', data: 
      <></>
      }}
    
    
    ></ContentFormat>


  </>
  )
}