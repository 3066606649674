// network
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoader from "../webx/webb-loader";
import NavsButtonBack from "../webx/navs-button-back";
import NavsButtonNext from "../webx/navs-button-next";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { NetworkList } from "../../services/srvc-tokens-realm";


// lists --------------->


// code --------------->

export default function NetworkListModule() {

  const usxx = GetUserForm();
  const asset = GetUserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // indexx
  const [items, setItems] = useState(10)
  const [index, setIndex] = useState(1)
  const [total, setTotal] = useState(1)
  const [currIndex, setCurrIndex] = useState(1)
  const [nextIndex, setNextIndex] = useState()
  const [lastIndex, setLastIndex] = useState()
  const [text, setText] = useState('')

  const [list, setList] = useState([])
  const [data, setData] = useState([])

  const [search, setSearch] = useState()

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        
        setLoading(true);
        
        const result = (await NetworkList(({
          data: {user: asset.item, index: index, items: items},
          user: asset.item
        })))
        console.log (result.data)
        setData(result.data.list)
        // setList(result.data.users.sort((a,c) => c.wize - a.wize))

        // set index
        setTotal(result.data.count)
        setText(`${((index-1) * items )+1} - ${index * items < result.data.count ? index * items : result.data.count} of ${result.data.count}`)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[search, index, items]);

  // useEffect( () => {
  //   if (asset){
  //     const fetchData = async() => {
        
  //       var searchData = []

  //       if (search !== "") {
  //         console.log (search.toLowerCase())
          
  //         var result =  (list.filter(x=> 
  //           x.user.name.toLowerCase().includes(search.toLowerCase()) 
  //           || x.user.mail.toLowerCase().includes(search.toLowerCase()) 
  //         ));
  //         searchData = (result)
  //       }
  //       else {
  //         console.log(list)
  //         searchData = list
  //       }
        
  //       setData(searchData)
        
  //     }
  //     fetchData()
  //   } else {}
  // },[search, index, items]);


  const LastIndex = async() => {
    
    if(index !== 1) {
      setLastIndex(currIndex -1)
      setIndex(currIndex -1)
      setCurrIndex(currIndex -1)
    }
    else {}
  }

  const NextIndex = async() => {
    
    console.log('next')
    if(index !== total) {
      setNextIndex(currIndex +1)
      setIndex(currIndex +1)
      setCurrIndex(currIndex +1)
    }
    else {}
  }



  const handleClick = (item) => {
    // alert(`/bz/network/v/${item}`)
    navigate(`/${asset.form.substring(0,2)}/network/v/${item}`)
  }

  // if (loading) return <WebbLoader/>
  // if (!data || data.length===0) return <>Your Network is Your Networth</>

  return (
    <>
    {/* search-box */}
    <div className={!loading && data.length !== 0 ? 'rounded-wd': 'd-none'}>
      
      <div className="form-group mb-3">  
        <input type="text" className="form-control height-md border-none "              
          value={search}
          onChange={({ target }) => {setSearch(target.value); }}
          // disabled={data.length==0}
          placeholder="Search">
        </input>
      </div>

    </div>

    {/* loader */}
    <div className={loading ? '' : 'd-none'}>
      {/* <WebbLoader/> */}
    </div>

    {/* no search data */}
    <div className={!loading && search !== "" && data.length === 0 ? 'text-center': 'd-none'}>
      {/* <span><i className="bx bxs-circle text-color-danger"></i></span>
      <span className="ms-1"></span> */}
      <span className="text-color-tone">Tokenize Events and Grow Your Network</span>
    </div>

    {/* data */}
    <div className={!loading && (data && data.length > 0) ? 'rounded-wd border border-light back-color-wite': 'd-none'}>
      {data && data.length > 0 && data.map((item, i) => ( 

        <div className={i < data.length-1 ? 'border-bottom border-light' : ''} key={i}>
          
          <div className="d-flex cursor p-2 px-3 hitone"
            onClick={() => handleClick(item.user.mail)} >

            <div className="mt-1">
              <Jazzicon diameter={33} seed={jsNumberForAddress(item.user.name)} />
            </div>

            <div className="ms-2">
              <p className="m-0">
                {item.user.name || 'Alice Wonderland'}
              </p>
              <p className="text-small text-color-tone m-0">{item.user.mail}</p>
            </div>

            <div className="ms-auto text-end">
              <div className="p-2 mt-1 text-center back-color-wite hilite rounded-wd" 
                style={{height:'2.4rem', width:'2.4rem'}}>
                {item.tokens}
              </div>
            </div>

          </div>

        </div>

      ))}
    </div>

    {/* navs */}
    <WebbDividerSmall />
    <div className={!loading && data.length !== 0 ? '': 'd-none'}>
      <div className="d-flex justify-content-between">

        <div className="" onClick={()=>LastIndex()}>
          <NavsButtonBack />
        </div>

        <div className="">
          <p className="my-2 text-color-tone">{text}</p>
        </div>

        <div className="" onClick={()=>NextIndex()}>
          <NavsButtonNext />
        </div>

      </div>
    </div>
  
  </>

  )
}