// auth - firebase mail link
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoader from "../webx/webb-loader";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";


import { GetLocalUser, SetNewBusiness } from "../../services/srvc-auth-user";
import { BusinessCreate } from "../../services/srvc-users-realm";


export default function BusinessOnboardModule () {

  const asset = GetLocalUser()
  // console.log (asset)
  const navigate = useNavigate();

  const [form, setForm] = useState(false);

  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [data, setData] = useState({
    name: '',
    mail: '',
    bios: ''
  });
  


  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data.name!=='' && data.mail!=='') setForm(true);

  },[data]);

  const handleSubmit = async () => {

    setLoading(true);
    setSubmit(true);

    const datx = {
      name: data.name, 
      mail: data.mail, 
      bios: data.bios,
      active: true, 
      self: true,
      owner: true,
      user: asset.item
    }
    console.log (datx)

    const result = await BusinessCreate({data: datx, user: asset.item})
    console.log (result)
    setLoading(false);

    if (result.data) {
      setDone(true);
      
      // SetNewBusiness({usid: result.data})
      
      // history.push(`/onboard/web3/${result.data}`);
    }


    setLoading(false)
    
  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }



  if (loading) return <WebbLoader/>


  return (
  <>
    
    <div className={!loading && submit ? 'd-none' : ''}>

      <WebbModuleInfo data = {{text: 'Please enter business / dao / team details'}} />

      <WebbDividerSmall/>
      <div className={''}>

        <div className="">
          <div className="form-group mb-3">  
            <label className="form-label small">Name <FormNeeded/></label>
            <input type="text" className="form-control height-md  "
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.name}
              onChange={({ target }) => {handleChange("name", target.value); }}
              disabled={loading || submit}
              placeholder="name">
            </input>
          </div>

          <div className="form-group mb-3">  
            <label className="form-label small">Mail <FormNeeded/></label>
            <input type="text" className="form-control height-md  "
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.mail}
              onChange={({ target }) => {handleChange("mail", target.value); }}
              placeholder="daos-team@wize">
            </input>
          </div>

          <div className="form-group mb-3">  
            <label className="form-label small">About Me (Bio) <FormNeeded/></label>
            <textarea className="form-control" rows="3" 
              style={{fontSize:'0.9rem'}}
              value={data.bios}
              onChange={({ target }) => {handleChange("bios", target.value); }}
              disabled={loading || submit}
              placeholder="short bio">
            </textarea>
          </div>


        </div>

      </div>

      <WebbDividerMedium />
      <div className={submit ? 'd-none' : ''}>
        <div className="d-flex justify-content-between">

        <button className={`btn btn-light border back-color-wite rounded-wd button text-small`}
          type="button"
          onClick={()=> { navigate('/auth/next')}}
        >{loading ? 'Please Wait...' : 'Cancel'}</button>

        <button className={`btn btn-info border-none back-color-main text-color-wite rounded-wd text-small`}
          disabled={!form || loading}
          type="button"
          onClick={()=> { handleSubmit()}}
        >{loading ? 'Please Wait...' : 'Continue'}</button>

        </div>


      </div>

    </div>

    <div className={submit && loading ? '' : 'd-none'}>
      <WebbLoader />
    </div>

    <div className={submit && !loading && done ? '' : 'd-none'}>
      <div className="">
        <h1 className="lead text-color-main">Congratulations !</h1>
        <p className="">Your Account is Created</p>
        <p className="d-none"></p>
      </div>

      <WebbDividerMedium />
      <div className="">
        <span className="p-3 px-4 back-color-next text-color-wite rounded-wd text-small" 
          onClick={()=>navigate('/auth/next')}
        >Continue</span>
      </div>

    </div>

    <div className={submit && !loading && !done ? '' : 'd-none'}>
      <div className="">
        <h1 className="lead text-color-main">Error !</h1>
        <p className="">Please try again / use a different email</p>
        <p className="d-none"></p>
      </div>

      <WebbDividerMedium />
      <div className="">
        <span className="p-2 px-4 back-color-next text-color-wite rounded-wd text-small" 
           onClick={()=> window.location.reload() }
        >Continue</span>
      </div>

    </div>

  </>

  )
}