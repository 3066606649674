// contacts
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";

import WebbLoader from "../webx/webb-loader";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import FileSaveMD from "../../services/srvc-filesave-cweb-md";
import FileSaveMX from "../../services/srvc-filesave-cweb-mx";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { EventsDetails } from "../../services/srvc-events-realm";


// lists --------------->
const listFormats = require('../../static/data-token-formatx.json').data
const listFeatures = require('../../static/data-formats-feat.json').data

const listModes = ['Offline', 'Online']

// code --------------->

export default function EventsViewModule() {

  const usxx = GetUserForm();
  const asset = GetUserForm() === "in" ? GetLocalUser() : GetLocalBusiness();

  const {id} = useParams();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect( () => {
    if (asset){

      const fetchData = async() => {
        setLoading(true);

        const result = await EventsDetails({data: {item: id}, user: asset.item})
        console.log (result)

        setData(result.data)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);

  if (loading) return <WebbLoader/>

  return (
  <>
    {/* info */}
    <p className="m-o text-color-tint text-center">{id}</p>
    
    {/* data */}
    <div className="media-standard">
      {/* <img src={data.head.link} alt={data.meta.memo} className="rounded-wd"></img> */}
    </div>

  </>

  )
}