import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/authcontext";

// views - main
import Main from "../views-xz/main";


// views - home
import HomeUser from "../views-home/home-indx";
import HomeBusiness from "../views-home/home-bznx";

// views - events
import Events from "../views-events/events";
import EventsCreate from "../views-events/events-create";
import EventsView from "../views-events/events-view";
import EventsEdit from "../views-events/events-edit";

// views - network
import Network from "../views-network/network";
import NetworkTeam from "../views-network/network-team";
import NetworkTeamInvite from "../views-network/network-invite-team";
import NetworkUserInvite from "../views-network/network-invite-user";

// views - transfers
import Transfers from "../views-transfers/transfers";

// views - tokens
import TokensMint from "../views-tokens/tokens-mint";

// views - data
import DataList from "../views-data/data-list";


// views - user
import UserCredits from "../views-user/user-credits";


// views - auth
import AuthMailFirebase from "../views-auth/auth-mail-firebase";
import AuthMailCheckFirebase from "../views-auth/auth-mail-check";
import AuthNext from "../views-auth/auth-next";
import AuthSessionX from "../views-auth/auth-session-x";

import UserOnboard from "../views-onboard/user-onboard";
import BusinessOnboard from "../views-onboard/business-onboard";


const routes = [

  { route:'/', content: <Main />, auth:false },

  // main
  { route:'/in/home', content: <HomeUser />, auth:false },
  { route:'/bz/home', content: <HomeBusiness />, auth:false },

  // events
  { route:'/bz/events', content: <Events />, auth:false },
  { route:'/bz/events/new', content: <EventsCreate />, auth:false },
  { route:'/bz/events/v/:id', content: <EventsView />, auth:false },
  { route:'/bz/events/e/:id', content: <EventsEdit />, auth:false },


  // network
  { route:'/bz/network', content: <Network />, auth:false },
  { route:'/bz/network/invite', content: <NetworkUserInvite />, auth:false },

  { route:'/bz/teams', content: <NetworkTeam />, auth:false },
  { route:'/bz/teams/invite', content: <NetworkTeamInvite />, auth:false },

  // transfers
  { route:'/bz/transfers', content: <Transfers />, auth:false },

  // tokens
  { route:'/bz/tokens/mint', content: <TokensMint />, auth:false },


  // data
  { route:'/bz/data', content: <DataList />, auth:false },


  // user
  { route:'/bz/credits', content: <UserCredits />, auth:false },

  // auth
  { route:'/auth', content: <AuthMailFirebase />, auth: false },
  { route:'/auth/check', content: <AuthMailCheckFirebase />, auth: false },
  { route:'/auth/next', content: <AuthNext />, auth: true },
  { route:'/auth/x', content: <AuthSessionX />, auth: true },


  { route:'/user/onboard', content: <UserOnboard />, auth: true },
  { route:'/business/onboard', content: <BusinessOnboard />, auth: true },

]


export default function RouteX() {

  const { user } = useAuth();
  // console.log (user)

  return (
    <Routes>
      {routes.map ((item,i)=>(item.auth
        ? <Route key={i} path={item.route} element={!user ? <Navigate to='/' replace /> : item.content} />
        : <Route key={i} path={item.route} element={item.content} />
      ))}
    </Routes>
  );
}