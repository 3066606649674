// main
import {Helmet} from "react-helmet";

import ContentFormat from "../content/webz/content-format-xw";
import WebbHeader from "../content/webz/webb-header-xw";
// import WebbFooter from "../content/webz/webb-footer-xw";

import WebbDividerSmall from "../content/webx/webb-divider-sm";
import WebbDividerMedium from "../content/webx/webb-divider-md";


import EventsListModule from "../content/events/events-list";

const listEvents = ['current', 'old']

export default function Events () {
  
  const meta = {
    name: 'Events',
    memo: '',
    head: ''
  }

  return(

  <>
    <Helmet>
      <title>{meta.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
      <meta name="description" content={process.env.REACT_APP_WEBB_SITE_MEMO}/>
      <link rel="canonical" href="https://tokenize.ee" />
    </Helmet>
    
    <ContentFormat 
      
      name = {meta.name}
      header = {{ size: 'medium', show: true, data: 
      <>
        <div className="">
          <WebbHeader data = {{name: meta.name, home: '/', link: '/'}}/>
        </div>
      </>
      }}

      media = {{ size: 'xtra', data: 
      <></>
      }}

      content = {{ size: 'medium', data: 
      <>

        <WebbDividerMedium />
        <div className="">
        {listEvents && listEvents.map((item, i) => (
          <span 
            className={`p-2 px-3 rounded-md me-2 cursor hirich text-uppercase text-small ${item === '' ? 'back-color-rich' : 'back-color-wite'}`} 
            key={i}
            onClick={() => alert('mode',item)}
          >
            {item}
          </span>
        ))}
        </div>

        <WebbDividerMedium />
        <EventsListModule />


        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
      </>
      }}
    
      footer = {{ size: 'medium', data: 
      <></>
      }}
    
    
    ></ContentFormat>


  </>
  )
}