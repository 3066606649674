// auth - firebase mail link
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { auth } from '../../services/firebase'
import { sendSignInLinkToEmail } from "firebase/auth";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoader from "../webx/webb-loader";
import WebbSpinner from "../webx/webb-spinner";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";


export default function AuthMailFirebaseModule () {

  const navigate = useNavigate();

  const [form, setForm] = useState(false);
  const [text, setText] = useState('');
  
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [data, setData] = useState({
    mail: ''
  })


  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data.name!=='' && data.mail!=='') setForm(true);

  },[data]);

  const handleSubmit = async () => {

    setLoading(true);
    setSubmit(true);

    // send link
    const base = window.location.hostname === 'localhost' 
      ? 'http://localhost:3000'
      : process.env.REACT_APP_WEBB_SITE_LINK

    const actionCodeSettings = {
      url:  base + '/auth/check',
      handleCodeInApp: true
    };

    await sendSignInLinkToEmail(auth, data.mail, actionCodeSettings)
    .then(() => {
      window.localStorage.setItem('authmail', data.mail );
      setDone(true)
    })
    .catch((error) => {
      setDone(false)
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode,errorMessage);
    });
    
    setLoading(false)
    
  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }



  if (loading) return <WebbSpinner/>


  return (
  <>
    
    <div className={submit ? 'd-none' : ''}>

      <WebbModuleInfo data = {{text: 'Please Enter Email to get login link.'}} />

      <WebbDividerSmall/>
      <div className={''}>

        <div className="">
          <div className="form-group mb-3">  
            <label className="form-label small">Email Address <FormNeeded/></label>
            <input type="text" className="form-control height-md  "
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.mail}
              onChange={({ target }) => {handleChange("mail", target.value); }}
              disabled={loading || submit}
              placeholder="alice@wize.ws">
            </input>
          </div>



        </div>

      </div>

      <WebbDividerMedium />
      <div className={submit ? 'd-none' : ''}>
        <div className="d-flex justify-content-between">

        <button className={`btn btn-light border back-color-wite rounded-wd button text-small`}
          type="button"
          onClick={()=> { navigate('/')}}
        >{loading ? 'Please Wait...' : 'Cancel'}</button>

        <button className={`btn btn-info border-none back-color-main text-color-wite rounded-wd text-small`}
          disabled={!form || loading}
          type="button"
          onClick={()=> { handleSubmit()}}
        >{loading ? 'Please Wait...' : 'Continue'}</button>

        </div>


      </div>

    </div>


    <div className={submit && done ? '' : 'd-none'}>
      <div className="">
        <h1 className="text-lead text-color-main mb-3">Please Check Your Email !</h1>
        <p className="text-normal">We have sent a login link to <strong>{data.mail}</strong></p>
        <p className="d-none">Please check your email (including spam)</p>
      </div>

      <WebbDividerMedium />
      <div className="">
        <p className="text-color-tone cursor" onClick={()=>window.location.reload()}>Try Again / Use Different Email</p>
      </div>

    </div>

  </>

  )
}