// content
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbSpinner from "../webx/webb-spinner";

import { EventsList } from "../../services/srvc-events-realm";


const list = require('../../static/data-events.json').data.filter(x => x.actv)
const listMonth = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

export default function EventsListModule () {

  const asset = {user: ''}

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState() // (list.filter(item => item.actv));
  const sort = [""] // [...new Set(data.map(item=>item.sort))];

  const [item, setItem] = useState([])



  useEffect( () => {
    if (asset){

      const fetchData = async() => {
        setLoading(true);

        const result = await EventsList({data: {}, user: asset.item})
        console.log (result)

        setData(result.data.list)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);

  const handleClick = (item) => {
    // navigate(`/${asset.form.substring(0,2)}/events/v/${item}`)
    navigate(`/bz/events/v/${item}`)
  }

  if (loading) return <WebbSpinner/>

  return (
  <>
    <WebbDividerSmall />
    <div className="row row-cols-1 row-cols-md-3 g-3">
      {data && data.map((item, i) => (
        <div className="col m-0 mb-3" key={i}>
          
            <div className="card h-100 border-none p-0 m-0 back-color-none cursor" 
              onClick={() => handleClick(item.item)}>

              <div className="media-standard">
              
                <img
                  src={item.banner.link} // Route of the image file
                  className="img-fluid w-100 rounded-wd"
                  alt="..."
                />
                <div className='btn back-color-wite text-dark text-small border-none rounded-md m-0 p-0 pt-1' style={{width: '2.5rem', height:'2.5rem'}}>
                  {/* <p className="m-0 p-0"  style={{lineHeight:'1rem'}}>{item.date.stts.time || '******'}</p> */}
                  <p className="text-lead text-bold m-0 p-0"  style={{lineHeight:'1rem'}}>{item.date.stts.time.substring(8,10) || '******'}</p>
                  <p className="small m-0 p-0" style={{lineHeight:'1rem'}}>{listMonth[item.date.stts.time.substring(5,7)-1] || '******'}</p>
                  <p className="m-0 p-0 d-none"  style={{lineHeight:'1rem'}}>{item.date.stts.time.substring(0,4) || '******'}</p>
                </div>
              </div>
              
              
              <div className="card-body m-0 p-2">
                
                {/* <div className="d-none d-md-block">
                  <p className="text-small m-0 mb-1 text-uppercase">{item.site}</p>
                </div>
                <div className="d-md-none">
                  <p className="text-small m-0 text-uppercase">{item.site.name}</p>
                </div> */}
                <h3 className="text-normal text-sm m-0 mb-1">{item.meta.name}</h3>
                {/* <p className="text-md m-0 mb-2">{item.memo}</p> */}
              
                {/* <p className="text-small text-sm m-0 d-none">{'item.auth'}</p> */}
              </div>
              
            </div>
          
            
        </div>
        
      ))}        
    </div>

  </>
  );
}