// main
import {Helmet} from "react-helmet";

import ContentFormat from "../content/webz/content-format-xz";
import WebbHeader from "../content/webz/webb-header-xw";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";

import AuthSessionXModule from "../content/auth/auth-session-x";


export default function AuthSessionX () {
  
  const meta = {
    name: 'Account Access'
  }

  return(

  <>
    <Helmet>
      <title>{meta.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}</title>
      <link rel="canonical" href="http://mysite.com/example" />
    </Helmet>

    <ContentFormat 
      
      name = {meta.name}

      header = {{ size: 'medium', data: 
      <>
        <div className="">
          <WebbHeader data = {{name: meta.name, home: '/', link: '/'}}/>
        </div>
      </>
      }}

      media = {{ size: 'xtra', data: 
      <>
        <div className="">
         
        </div>
      </>
      }}

      content = {{ size: 'mini', data: 
      <>
        <WebbDividerSmall />
        <AuthSessionXModule />
      </>
      }}
    
      footer = {{ size: 'medium', data: 
      <>
        <div className="">
        
        </div>
      </>
      }}
    
    
    ></ContentFormat>


  </>
  )
}