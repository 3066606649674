// main
import { useParams } from "react-router-dom";

import ContentFormat from "../content/webz/content-format-xz";
import WebbHeader from "../content/webz/webb-header-xx";
import WebbIconFull from "../content/webx/webb-icon-full";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";

import BusinessOnboardModule from "../content/onboard/onboard-daos";



export default function BusinessOnboard () {
  
  const meta = {
    name: 'User Account'
  }

  return(

  <>
    <ContentFormat 
      
      name = {meta.name}

      header = {{ size: 'medium', data: 
      <>
        <div className="">
          <WebbHeader data = {{name: meta.name, home: '/', link: '/auth/next'}}/>
        </div>
      </>
      }}

      media = {{ size: 'xtra', data: 
      <>
        <div className="">
         
        </div>
      </>
      }}

      content = {{ size: 'mini', data: 
      <>
        <WebbDividerSmall/>
        <WebbIconFull />

        <WebbDividerSmall/>
        <BusinessOnboardModule />

      </>
      }}
    
      footer = {{ size: 'medium', data: 
      <>
        <div className="">
        
        </div>
      </>
      }}
    
    
    ></ContentFormat>


  </>
  )
}