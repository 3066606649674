// contacts
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";

import WebbLoader from "../webx/webb-loader";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import FileSaveMD from "../../services/srvc-filesave-cweb-md";
import FileSaveMX from "../../services/srvc-filesave-cweb-mx";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { EventsCreate } from "../../services/srvc-events-realm";


// lists --------------->
const listFormats = require('../../static/data-token-formatx.json').data
const listFeatures = require('../../static/data-formats-feat.json').data

const listModes = ['Offline', 'Online']

// code --------------->

export default function EventsCreateModule() {

  const usxx = GetUserForm();
  const asset = GetUserForm() === "in" ? GetLocalUser() : GetLocalBusiness();

  const {id} = useParams();

  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);
  const [form, setForm] = useState(false);
  const [check, setCheck] = useState(true);

  const [fileActive, setFileActive] = useState();
  const [fileInactive, setFileInactive] = useState();
  const [fileBanner, setFileBanner] = useState();

  const [feat, setFeat] = useState();

  const [data, setData] = useState({
    name: '',
    memo: '',
    media: { link: '', mime: '' },
    sttd: ((new Date()).toISOString().substring(0,10)),
    sttt: ((new Date()).toISOString().substring(11,16)),
    entd: ((new Date()).toISOString().substring(0,10)),
    entt: ((new Date()).toISOString().substring(11,16)),
    mode: '', 
    sitename: '',
    sitelink: '',
    book: false,
  })


  // form validation
  useEffect( () => {
    setForm(false);
    if (data.name!=='' && data.memo!=='' )  //&& fileBanner && fileBanner!==''
      setForm(true);

  },[data, fileBanner]);

  const handleSubmit = async () => {

    console.log (data)
    // setSubmit(true)
    // setLoading(true)
    
    const datx = {
      name: data.name,
      memo: data.memo,
      media: fileBanner,
      startdate: {time: data.sttd +'T'+data.sttt+':00.000Z', zone: ''},
      enddate: {time: data.entd +'T'+data.entt+':59.999Z', zone: ''},
      location: {
        name: data.sitename, link: data.sitelink, mode: data.mode
      }
    }
    console.log ({data: datx, user: asset.item})

    const result = await EventsCreate({data: datx, user: asset.item})
    console.log (result)
    if (result.data) {
      setDone(true)
    }
    else {setDone(false)}
    setLoading(false)

  };

  const handleMediaBanner = async(filedata) => {
    console.log (filedata);
    setFileBanner(filedata);
  }


  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }



  if (loading) return <WebbLoader/>

  return (
  <>
    {/* info */}

    
    {/* data */}
    <div className={submit ? 'd-none' : ''}>

      {/* banner */}
      <p className="text-lead text-bold m-0">Event Banner / Header Media</p>
      <p className="">Media dimensions must be 900 x 300 px (3:1 ratio)</p>
      <FileSaveMD size='media-standard' media={handleMediaBanner} />

      {/* meta */}
      <WebbDividerMedium />
      <p className="text-lead text-bold">Event Details</p>
      <div className="">
        <div className="mb-3">  
          <label className="form-label">Name <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md border-none"
            style={{fontSize:'0.9rem', height:'2.7rem'}} 
            value={data.name}
            onChange={({ target }) => {handleChange("name", target.value); }}
            placeholder="Enter Name">
          </input>
        </div>      

        <div className="mb-3">  
          <label className="form-label">Memo <FormNeeded/></label>
          <textarea className="form-control  border-none" 
            style={{fontSize:'0.9rem'}} rows="6"
            onChange={({ target }) => {handleChange("memo", target.value); }}
            placeholder="Enter Details"></textarea>
        </div>

        <WebbDividerMedium />
        <p className="text-lead text-bold">Date & Time</p>
        <div className="row g-3">
          <div className="col-sm">
            <label className="form-label">Start Date-Time <FormNeeded/></label>
            <input type="date" 
              className="form-control height-md  border-none" 
              style={{fontSize:'0.9rem'}}
              value={data.sttd}
              onChange={({ target }) => {handleChange("sttd", target.value); console.log(target.value)}}
            ></input>
          </div>
          <div className="col-sm">
            <label className="form-label text-color-lite">{'*'}</label>
            <input type="time" 
              className="form-control height-md border-none" 
              style={{fontSize:'0.9rem'}}
              value={data.sttt}
              onChange={({ target }) => {handleChange("sttt", target.value); console.log(target.value)}}
            ></input>
          </div>
        </div>

        <WebbDividerMedium />
        <div className="row g-3">
          <div className="col-sm">
            <label className="form-label">End Date-Time <FormNeeded/></label>
            <input type="date" 
              className="form-control height-md  border-none" 
              style={{fontSize:'0.9rem'}}
              value={data.entd}
              onChange={({ target }) => {handleChange("entd", target.value); console.log(target.value)}}
            ></input>
          </div>
          <div className="col-sm">
            <label className="form-label text-color-lite">{'*'}</label>
            <input type="time" 
              className="form-control height-md border-none" 
              style={{fontSize:'0.9rem'}}
              value={data.entt}
              onChange={({ target }) => {handleChange("entt", target.value); console.log(target.value) }}
            ></input>
          </div>
        </div>


        <WebbDividerMedium />
        <WebbDividerSmall />
        <p className="text-lead text-bold">Location Details</p>

        <WebbDividerSmall />
        <label className="form-label mb-3">Mode <FormNeeded/></label>
        <div className="mb-3">
        {listModes && listModes.map((item, i) => (
          <span className={`p-2 px-3 rounded-md me-2 cursor hitone ${item === data.mode ? 'back-color-rich' : 'back-color-wite'}`} 
            key={i}
            onClick={() => handleChange('mode',item)}
          >
            {item}
          </span>
        ))}
        </div>

        <WebbDividerSmall />
        <div className="mb-3">  
          <label className="form-label">Name <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md border-none"
            style={{fontSize:'0.9rem', height:'2.7rem'}} 
            value={data.sitename}
            onChange={({ target }) => {handleChange("sitename", target.value); }}
            placeholder="Location Name">
          </input>
        </div>  

        <div className="mb-3">  
          <label className="form-label">Link (Google Maps, Online Link etc) <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md border-none"
            style={{fontSize:'0.9rem', height:'2.7rem'}} 
            value={data.sitelink}
            onChange={({ target }) => {handleChange("sitelink", target.value); }}
            placeholder="https://">
          </input>
          </div>  

      </div>


      {/* feat */}
      <WebbDividerMedium />
      <p className="text-lead text-bold">Event Attributes</p>
      <div className={`mb-3`}>
        <div className="form-check">
          <input className="form-check-input" 
            type="checkbox" 
            checked={data.book}
            onChange={() => handleChange('book', !data.book)}
          ></input>
          <label className="form-check-label text-normal text-bold">
            Require Host Approval
          </label>
          <p>Only approved guests will receive personalized ticket with QR Code for check-in</p>
        </div>
      </div>

      {/* feat */}
      

      {/* action */}
      <WebbDividerMedium/>
      <WebbDividerMedium/>
      <div className="d-flex justify-content-between">
        <button className="btn btn-light text-small border rounded-wd  d-none"
          onClick={() => handleSubmit()}
        >
          Cancel
        </button>
        <div className="mx-1 d-none"></div>
        <button className="btn btn-primary text-small border-none rounded-wd"
          type="button"
          disabled={!form}
          onClick={() => handleSubmit()}
        >
          Create Event
        </button>
      </div>

    </div>

    {/* results */}
    <div className={!loading && submit ? '' : 'd-none'}>
      <WebbDividerSmall />
      
      <div className={done? 'p-3 back-color-wite rounded-wd text-center ': 'd-none'}>
        <i className="bi bi-check-circle-fill text-icon-md text-color-success"></i>
        <WebbDividerSmall/>
        <p className="m-0">Success! Your Collection is Created.</p>
      </div>

      <div className={done? 'd-none': 'p-3 back-color-wite rounded-wd text-center '}>
        <i className="bi bi-exclamation-circle-fill text-icon-md text-color-warning"></i>
        <WebbDividerSmall/>
        <p className="m-0">Error! Please try again.</p>
      </div>

    </div>


  </>

  )
}