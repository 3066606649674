// user
import { useEffect, useState } from "react";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

// import { TokenAccountStatus } from "../../services/srvc-tokens-realm";
// import { FormatsAccountStatus } from "../../services/srvc-formats-realm";


const statisticsList = [
  {name: 'events', code:'events', count: 0, live: 0, actv: true},
  {name: 'tickets', code:'tickets', count: 0, live: 0, actv: true},
  {name: 'minted', code:'minted', count: 0, live: 0, actv: true},
  {name: 'network', code:'users', count: 0, live: 0, actv: true}
]


export default function UserStatisticsModule() {

  const usxx = GetUserForm();
  const asset = GetUserForm() === "in" ? GetLocalUser() : GetLocalBusiness();

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState( statisticsList )

  useEffect( () => {
    if (asset) {
      const fetchData = async() => {
        
        setLoading(true);
        
        // const tokens = await TokenAccountStatus({
        //   data: {user: asset.item},
        //   user: asset.item
        // })
        // console.log (tokens)
        // const collections = await FormatsAccountStatus({
        //   data: {user: asset.item},
        //   user: asset.item
        // })

        // var statistics = statisticsList.map(item => ['users', 'mint', 'claim'].includes(item.code)
        // ? {...item, count: tokens ? tokens.data[item.code]: 0}
        // : item )

        // statistics = statistics.map(item => ['collections'].includes(item.code)
        // ? {...item, count: collections.data.collections}
        // : item )
      
        // setData(statistics)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);


  return (
  <>

    <div className="row row-cols-4 g-1">
    {data && data.map((item, i) => (item.actv ?

      <div className="text-center" key={i}>

        <div className="back-color-wite p-0 py-2 mb-1 rounded-wd">
          <p className="text-bold m-0" style={{fontSize:'1.5rem'}}>{item.count || '***'}</p>
          <p className="text-small text-color-tone m-0 p-0">{item.name.toUpperCase()}</p>
        </div>

      </div>
    
    : ''))}
    </div>



  </>
  )
}
  